import './style.css';
import { AiFillLinkedin } from  'react-icons/ai';
import logoLogic from '../../content/images/Logo Logictron.jpg'

const Footer = () => {
    return(
        <footer className='footerContainer'>
            <div className='logoFooterContainer'>
                <img src={logoLogic} alt="@2022 LOGICTRON" className='logicLogo'/>
            </div>
            <ul className='footerList'>
                <AiFillLinkedin className='iconFooter'/>
                <li><a href='https://www.linkedin.com/company/logictron-consultoria-e-informatica-ltda/about/' target="blank" className='linkStyleFooter'>LINKEDIN</a></li>
            </ul>
        </footer>
    );
}

export default Footer