import { useState, useEffect, useCallback } from 'react';
import logoMarca from '../../content/images/pngChaoDeFabrica.png';
import { Link } from 'react-router-dom';
import './style.css';
import { AiOutlineAlignCenter } from 'react-icons/ai'

const Nav = () => {
  const [color, setColor] = useState(false);
  const [hamburguer, setHamburguer] = useState(false)

  const changeColor = () => {
    setColor(window.scrollY >= 250)
  }
  useEffect(() => {
    window.addEventListener('scroll', changeColor);
  }, [])

  const callback = useCallback(() => {
    setHamburguer(!hamburguer)
  }, [hamburguer])

  return(
    <nav className={!color ? 'Navbar' : 'NavbarColor'}>
      {
          window.screen.width <= 540
          ? 
            <ul className='ListStyle'>
              <li>
                <AiOutlineAlignCenter 
                  size={30}
                  onClick={callback}
                  className='hamburguerButton'
                />
              </li>
              {
                hamburguer &&
                  <nav className='hamburguerNavBar'>
                    <ul className='hamburguerList'>
                      <li>
                        <Link to='/'><h3 className='linkStyle'>Home</h3></Link>
                      </li>
                      <li>
                        <Link to='/jobs'><h3 className='linkStyle'>Carreira</h3></Link>
                      </li>
                      <li className='listMgTop'>
                        <a href='#prodeservicos'>Produtos/Serviços</a>
                      </li>
                      <li className='listMgTop'>
                        <a href='#clientes'>Clientes</a>
                      </li>
                      <li className='listMgTop'>
                        <a href='#parceiros'>Parceiros</a>
                      </li>
                      <li className='listMgTop'>
                        <a href='#faleconosco'>Fale Conosco</a>
                      </li>
                    </ul>
                  </nav> 
              }
            </ul>
          : (
            <ul className='ListStyle'>
              <li>
                <img src={logoMarca} alt='Chão de Fábrica' className='LogoStyle' />
              </li>
              <div className='textNav'/>
              <li>
                <Link to='/'><h3 className='linkStyle'>Home</h3></Link>
              </li>
              <li>
                <Link to='/jobs'><h3 className='linkStyle'>Carreira</h3></Link>
              </li>
              <li>
                <a href='#prodeservicos'>Produtos/Serviços</a>
              </li>
              <li>
                <a href='#clientes'>Clientes</a>
              </li>
              <li>
              <a href='#parceiros'>Parceiros</a>
              </li>
              <li>
                <a href='#faleconosco'>Fale Conosco</a>
              </li>
            </ul>
          )
      }
    </nav>
  );
}

export default Nav;