import './style.css';
import { useState, useCallback } from 'react';
import { motion } from 'framer-motion';
import { AiOutlineMail } from 'react-icons/ai';
import ReactModal from 'react-modal';
import emailJs from '@emailjs/browser';
import logoParc from '../../content/images/aib.png';
import logoParc5 from '../../content/images/OneSolution.png';
import logoParc2 from '../../content/images/chacon.png';
import logoParc3 from '../../content/images/letnis.png';
import logoParc4 from '../../content/images/autech.png';
import logoParc6 from '../../content/images/chr.png';
import video from '../../content/images/ch1.mp4';
import Costumer from './Costumer_Logos/costumer';

const Content = () => {
    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [msg, setMsg] = useState('');
    const [mailModal, isMailModal] = useState(false);

    const logosParceiros = {
        logoParc,
        logoParc2,
        logoParc3,
        logoParc4,
        logoParc5,
        logoParc6
    }

    const linkButton = (url) =>{
        window.open(url, '_blank', 'noopener,noreferrer');
    }

    const enviarEmail = (e) =>{
        e.preventDefault()
         const templateForm = {
             from_name: nome,
             message: msg,
             email: email

         }

         try {    
            emailJs.send('service_fbom3g3', 'template_dtplt0x', templateForm, 'DPILes91QlCqRTh09',);
            console.log("E-mail enviado");
            setNome("")
            setEmail("")
            setMsg("")
            mailCallback()

         } catch(error) {
            console.log("ERROR", error);

        }
    }


    const mailCallback = useCallback(() => {
        isMailModal(!mailModal);
     } ,[mailModal])

    return(
        <div>
            <div className='contentContainer'>
                <motion.div className='background content' initial={{ y: 800 }} animate={{y: 0}}/>
                <div className='content'>
                    <h2 className='Title'>LOGICTRON CHÃO DE FÁBRICA</h2>
                    <p className='bodyText'>Chão de Fábrica é um sistema MES (ManufacturingExecution System) preparado para atender os requisitos da Indústria 4.0, desenvolvido com tecnologia aberta, permite uma apurada gestão das condições da produção. Otimiza a utilização dos recursos produtivos, auxiliando a maximização dos resultados. Agiliza o fluxo das informações de maneira padronizada e sem redundâncias atendendo aos pré-requisitos de segurança e confiabilidade.
                        <h4>“O MES – Chão de Fábrica é a lupa do ERP para as indústrias”</h4>
                    </p>
                </div>
            </div>
            <div className='contentContainer'>
                <div className='content'>
                    <h2 className='Title'>ABRANGÊNCIA DO SISTEMA</h2>
                    <ul className='listSystemText'>
                        <li>Permite integração com quaisquer ERPs.</li>
                        <li>Integra-se com diferentes dispositivos, checkweigher (balanças), coletores de dados, RFID, impressoras marcadoras, paletizadoras, robôs, entre outros.</li>
                        <li>Captura sinal diretamente das máquinas e processos de forma simples e a baixo custo.</li>
                        <li>Painéis para gestão a vista</li>
                        <li>Relatórios com BI próprio e integração com os principais BI do mercado.</li>
                        <li>Monitoramento em tempo real pelo celular.</li>
                        <li>Terminais para apontamento possíveis de ser utilizados em diferentes dispositivos.</li>
                        <li>Sequenciamento de produção (APS)</li>
                    </ul>
                </div>
                <motion.div className='background background2 content' initial={{ y: 800 }} animate={{y: 0}}/>
            </div>
            <div className='contentContainer' id='prodeservicos'>
                <motion.div className='background background3 content' initial={{ y: 800 }} animate={{y: 0}}/>
                <div className='content'>
                    <h2 className='Title'>PRODUTOS E SERVIÇOS</h2>
                    <ul className='listSystemText'>
                        <li>Sistema MES (Manufacturing Execution System)</li>
                        <li>Sistema APS (Advanced Planning And Schedule)</li>
                        <li>Integração entre sistemas e dispositivos</li>
                        <li>Consultoria e projetos em SAP e TOTVS</li>
                        <li>Desenvolvimento de sistemas em diferentes plataformas</li>
                        <li>Consultoria e treinamentos</li>
                        <li>Infraestrutura de TI</li>
                    </ul>
                </div>
            </div>
            <div className='contentContainer'>
                <div className='content contentVideoHght'>
                    <h2 className='Title'>COMO FUNCIONA LOGICTRON CHÃO DE FÁBRICA</h2>
                    <p className='bodyText'>Através da captura de sinal diretamente das máquinas o sistema apresenta em tempo real a Performance, a Disponibilidade e a Qualidade, em alguns casos o refugo também é registrado por sinal de rejeito. Com estes indicadores o OEE (Overall Equipment Effectiveness) também é apresentado em tempo real, propiciando a exposição dos indicadores em painéis de gestão à vista, visualização em dispositivos móveis e computadores. Através da integração os apontamentos são inseridos diretamente no ERP resultando em agilidade e confiabilidade nas quantidades e tempos.</p>
                    <video 
                        className='video' 
                        src={video} 
                        autoPlay={true} 
                        controls={true}
                    >
                        Ops... Media not found
                    </video>
                </div>
            </div>

            <div className='contentContainer'>
                <div className='content contentMidleLine'>
                    <h2 className='Title'>SOBRE NÓS</h2>
                    <p className='bodyText'>A LOGICTRON Consultoria e Informática Ltda é uma empresa nacional que atua há mais de 20 anos no mercado, atendendo a indústrias de todos os ramos de atividades com produtos e serviços voltados para a gestão de produção. A larga experiência dos gestores e colaboradores permite um atendimento personalizado, ágil e com soluções que simplificam os controles e permitem implementações rápidas com resultados imediatos.</p>
                </div>
                <div className='content'>
                    <h2 className='Title'>NOSSO DIFERENCIAL</h2>
                    <p className='bodyText'>O SISTEMA Chão de Fábrica integra-se a quaisquer ERPs através de diferentes funcionalidades. Utiliza-se de tecnologias padrões de mercado propiciando o aproveitamento de toda infraestrutura já existente. Temos autorização para negociação de todo o projeto através de programas do BNDES.</p>
                </div>
            </div>

            <div className='contentContainer' id='clientes'>
                <div className='contentClientLogo'>
                    <h2 className='Title'>CLIENTES EM TODOS OS RAMOS DE ATIVIDADES</h2>
                    <div className='logosClientContainer'>
                        <Costumer />
                    </div>
                </div>
            </div>

            <div className='contentContainer' id='parceiros'>
                <div className='contentPartner'>
                    <h2 className='Title'>PARCEIROS</h2>
                    <div className='logosPartnerContainer'>
                        <ul className='listPartner'>
                            <li>
                                <img src={logosParceiros.logoParc} alt='Logo parceiro' className='logoPartner' onClick={() => linkButton('https://www.aib-solutions.com.br/')}/>
                                <p className='bodyText'>Consultoria de TOTVS e Inteligência Artificial. Tendo profissionais de  qualidade com mais de 20 anos no mercado.</p>
                            </li>
                            <li>
                                <img src={logosParceiros.logoParc3} alt='Logo parceiro' className='logoPartner' onClick={() => linkButton('https://www.letnis.com.br/')}/>
                                <p className='bodyText'>Consultoria com atuação global, parceira SAP há mais de 10 anos e trabalha para otimizar processos e negócios através da tecnologia. Está presente no Brasil desde o ano 2000 onde iniciou suas atividades através do grande desafio de implantação do SAP ERP em importantes empresas do segmento automotivo.</p>
                            </li>
                            <li>
                                <img src={logosParceiros.logoParc2} alt='Logo parceiro' className='logoPartner width' onClick={() => linkButton('http://www.chacon.inf.br/')}/>
                                <p className='bodyText'>A Chacon Informatica é uma empresa que foi fundada no ano de 2006, a partir do interesse de criar uma empresa focada no desenvolvimento econômico e tecnológico, reduzindo custos, otimizando o tempo e satisfazendo as necessidade de seus clientes.</p>
                            </li>
                            <li>
                                <img src={logosParceiros.logoParc4} alt='Logo parceiro' className='logoPartner width' onClick={() => linkButton('http://www.autech.com.br/')}/>
                                <p className='bodyText'>Empresa com tradição no desenvolvimento de soluções, sistemas e equipamentos. Produz e comercializa diretamente ou através dos parceiros comerciais: hardware para diversas aplicações nas áreas industrial, comercial, residencial e automotiva.</p>
                            </li>
                            <li>
                                <img src={logosParceiros.logoParc5} alt='Logo parceiro' className='logoPartner width' onClick={() => linkButton('https://onesolution.com.br/?gclid=CjwKCAjwzY2bBhB6EiwAPpUpZht9jingLn2nUGpAxPQgQdB2Xd3iMduS1nM3TwO6fd-5gRRsnI0BuBoCXMgQAvD_BwE')}/>
                                <p className='bodyText'>Somos uma empresa formada por profissionais com mais de 20 anos de experiência consolidada no ramo de TI. Atuamos  nos mercado Brasileiro, Norte Americano e América Latina com soluções customizadas aos segmentos de Data Analytics, Data Visualization, Cloud Solutions, Management Consulting e Business Inteligence.</p>
                            </li>
                            <li>
                                <img src={logosParceiros.logoParc6} alt='Logo parceiro' className='logoPartner'/>
                                <p className='bodyText'>Somos uma empresa de serviços, cujos profissionais contam com grande expertise na comercialização e representação de produtos e serviços de informática em especial sistemas e softwares. Atuamos em todas as frentes, desde a qualificação (SRD), prospecção, desenvolvimento, e negociação, além de fazermos um acompanhamento constante da satisfação de nossos clientes.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className='contentContainer' id='faleconosco'>
                <div className='content contentHght contentMidleLine'>
                    <h2 className='Title'>FALE CONOSCO</h2>
                    <form className='form' onSubmit={enviarEmail}>
                        <AiOutlineMail size={40} className="icon"/>
                        <input 
                            id='name' 
                            placeholder='Nome' 
                            className='input'
                            value={nome} 
                            onChange={(e) => setNome(e.target.value)} 
                            required
                        />
                        <input 
                            id='e-mail' 
                            placeholder='E-mail' 
                            className='input' 
                            pattern='[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$' 
                            title='O E-mail deve conter números, letras e um dominio (Gmail, Outlook, Hotmal etc.)' 
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <textarea 
                            placeholder='Mensagem' 
                            className="txtarea" 
                            value={msg}
                            onChange={(e) => setMsg(e.target.value)} 
                            required
                        />
                        <button className="button">ENVIAR</button>
                    </form>
                    <ReactModal isOpen={mailModal}>
                        <div className="modalContainer">
                            <h2 className='modalText'>E-mail Enviado</h2>
                            <button className='button' onClick={mailCallback}>FECHAR</button>
                        </div>
                    </ReactModal>
                </div>

                <div className='content contentHght'>
                    <h2 className='Title'>WEBMAIL</h2>
                    <p className='bodyText'>Acesso a colaboradores</p>
                    <button className='button' onClick={() => linkButton('https://webmail.jetmailx.com.br/')}>WEBMAIL</button>
                </div>
            </div>

        </div>
    );
}

export default Content;