import './style.css';
import Nav from '../components/Navbar/nav';
import Footer from '../components/Footer/footer';

const Jobs = () => {
    const jobs = [
        /*{
            title: 'Vaga teste',
            description: 'Descrição teste para a vaga'
        }*/
    ];

    return (
        <div>
            <div className='container'>
                <Nav />
                <div className='contentContainer'>
                    <div className='StartContentCarrer'>
                        <h2 className='titleCarroussel'>FAÇA PARTE DO NOSSO TIME</h2>
                        <p className='text mgTop'>PRECISAMOS DE PROFISSIONAIS COM ESTA QUALIFICAÇÃO</p>
                    </div>
                </div>
                <div className='contentContainer'>
                    <div className='jobContent'>
                        <ul className='jobList'>
                            {
                                jobs.length === 0 
                                ?
                                    <li>
                                        <h2 className='Title'>SEM VAGAS NO  MOMENTO :(</h2>
                                        <p className='jobText'>Sem vagas no momento, assim que houver alguma a página será atualizada</p>
                                    </li>
                                : (
                                    jobs.map(data => {
                                        return (
                                            <li key={data.id}>
                                                <h2 className='Title'>{data.title}</h2>
                                                <p className='jobText'>{data.description}</p>
                                            </li>
                                        );
                                    })
                                )
                            }
                        </ul>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default Jobs