import '../style.css';
import logo1 from '../../../content/images/marcas-tres-128x128.png';
import logo2 from '../../../content/images/logo-formica.png';
import logo3 from '../../../content/images/Nitto_Denko-Logo.wine.png';
import logo4 from '../../../content/images/Logo-Unimil-220px.png';
import logo5 from '../../../content/images/antilhas (1).png';
import logo6 from '../../../content/images/copave.png';
import logo7 from '../../../content/images/eco1.png';
import logo8 from '../../../content/images/kframe.png';
import logo9 from '../../../content/images/rss.png';
import logo10 from '../../../content/images/nortebag.png';
import logo11 from '../../../content/images/mercurio.png';
import logo12 from '../../../content/images/Rubberon.png';
import logo13 from '../../../content/images/fitafer.png';
import logo14 from '../../../content/images/Logo-jc.png';

const Costumer = () => {
    const costumerLogos = [
        {
            costumer: logo1
        },
        {
            costumer: logo2
        },
        {
            costumer: logo3
        },
        {
            costumer: logo4
        },
        {
            costumer: logo5
        },
        {
            costumer: logo6
        },
        {
            costumer: logo7
        },
        {
            costumer: logo8
        },
        {
            costumer: logo9
        },
        {
            costumer: logo10
        },
        {
            costumer: logo11
        },
        {
            costumer: logo12
        },
        {
            costumer: logo13
        },
        {
            costumer: logo14
        }
    ]

    return (
        costumerLogos.map(data => {
            return(
                <img src={data.costumer} alt="Logo Cliente" className="logoClient"/>
            );
        })
    );
}

export default Costumer