import './style.css';
import Nav from '../components/Navbar/nav';
import Content from '../components/Page_Content/content';
import Footer from '../components/Footer/footer';

const Home = () => {
  return (
    <div className="Container">
      <Nav />
      <div className='contentContainer'>
        <div className='StartContent'>
          {/*<h1 className='titleCarroussel'>SISTEMA MES</h1>
          <h2 className='text mgTop'>A PRODUÇÃO NA PALMA DA SUA MÃO</h2>*/}
        </div>
      </div>
      <Content />
      <Footer />
    </div>
  );
}

export default Home;