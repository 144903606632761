import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Home from './Pages/Home';
import Jobs from '../src/Pages/jobs';

const Routering = () => {
    return(
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/jobs' element={<Jobs />} />
            </Routes>
        </BrowserRouter>
    );
}

export default Routering

